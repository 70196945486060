import type { CheckoutCustomPayload } from "@product/rosetta-sdk";
import { safeSetLocalStorage } from "@product/scmp-sdk";
import { useCallback } from "react";

import { useLoginDialogStateHelper } from "scmp-app/components/login-dialog/hooks";
import {
  registerMixPanelParameters,
  trackMixPanel,
  useMixpanel,
} from "scmp-app/components/mixpanel";
import { sendGTMSubscribeVariables } from "scmp-app/components/tracking/google-tag-manager/apis";
import { getGoogleLoginUrl } from "scmp-app/lib/account";

import { RegiWallLoginMethodLocalstorageKey } from "./consts";

export const useRosettaCheckoutCustomEventHandler = () => {
  const { openLoginDialog } = useLoginDialogStateHelper();
  const mixpanel = useMixpanel();

  const handleCustomEvent = useCallback(
    (payload: CheckoutCustomPayload) => {
      const handleCheckoutCustomEventTracking = () => {
        const {
          eventName,
          params: { meter },
        } = payload;

        if (!eventName || !meter) return;

        switch (eventName) {
          case "meter-subscribe":
            sendGTMSubscribeVariables({ meter });
            registerMixPanelParameters(mixpanel.result, {
              "Metering Article Count": meter,
            });
            trackMixPanel(mixpanel.result, {
              name: "Subscription Metering",
              property: {
                "Metering Article Count": meter,
                "User Action": "Click Subscribe",
              },
            });
            break;
          case "meter-close-modal":
            trackMixPanel(mixpanel.result, {
              name: "Subscription Metering",
              property: {
                "Metering Article Count": meter,
                "User Action": "Close",
              },
            });
            break;
          case "meter-signin":
            trackMixPanel(mixpanel.result, {
              name: "Subscription Metering",
              property: {
                "Metering Article Count": meter,
                "User Action": "Sign in",
              },
            });
            break;
          case "meter-minimize":
            break;
          case "loginwall-meter-signin":
            trackMixPanel(mixpanel.result, {
              name: "Subscription Metering",
              property: { "Metering Article Count": meter, "User Action": "Sign in" },
            });
            break;
          case "loginwall-meter-subscribe":
            registerMixPanelParameters(mixpanel.result, { "Metering Article Count": meter });
            trackMixPanel(mixpanel.result, {
              name: "Subscription Metering",
              property: { "Metering Article Count": meter, "User Action": "Click Subscribe" },
            });
            break;
          case "sep-article-footer-close":
            trackMixPanel(mixpanel.result, {
              name: "Subscription Entry Point/Article Footer",
              property: { "User Action": "Close" },
            });
            break;
          case "onboarding-direct-checkout": {
            const {
              params: { action: eventAction, plan: period },
            } = payload;

            if (period) {
              const {
                params: { currency, price },
              } = payload;
              const plan = `${period === "year" ? "Annual" : "Monthly"}: Digital | ${currency}`;
              sendGTMSubscribeVariables({ meter, period, plan, price });
            }
            if (!eventAction) return;
            break;
          }
          case "sep-article-footer-support":
            trackMixPanel(mixpanel.result, {
              name: "Subscription Entry Point/Article Footer",
              property: { "User Action": "Support Us" },
            });
            break;
          case "meter-direct-checkout": {
            const {
              params: { action: eventAction, plan: period },
            } = payload;

            if (period) {
              const {
                params: { currency, price },
              } = payload;
              const plan = `${period === "year" ? "Annual" : "Monthly"}: Digital | ${currency}`;
              sendGTMSubscribeVariables({ meter, period, plan, price });
            }
            if (!eventAction) return;

            registerMixPanelParameters(mixpanel.result, {
              "Metering Article Count": meter,
            });
            trackMixPanel(mixpanel.result, {
              name: "Subscription Metering",
              property: {
                "Metering Article Count": meter,
                "User Action": "Click Subscribe",
              },
            });
            break;
          }
        }
      };

      handleCheckoutCustomEventTracking();

      switch (payload.eventName) {
        case "regiwall-google-signin":
          safeSetLocalStorage(RegiWallLoginMethodLocalstorageKey, "Google");
          location.href = getGoogleLoginUrl({
            registerSource: "loginwall",
          });
          break;
        case "loginwall-meter-signin":
        case "loginwall-meter-signup":
        case "regiwall-signin":
          openLoginDialog({
            description: "To continue reading, create your free account or log in now",
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              trigger_point: "paywall",
            },
            wallType: "regi",
          });
          break;
        case "regiwall-elections-meter-signup":
          openLoginDialog({
            description: "To continue reading, create your free account or log in now",
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              trigger_point: "paywall",
            },
            registrationTerm: "hkelections",
            wallType: "regi",
          });
          break;
        case "regiwall-elections-meter-redeem":
          const updatedUrl = new URL(window.location.href);
          // cspell: ignore hkelections
          updatedUrl.searchParams.set("hkelections", "true");
          window.location.href = updatedUrl.href;
          break;
        case "onboarding-direct-checkout":
          if (!payload?.params) return;
          const { params } = payload;
          if (params.plan) {
            sendGTMSubscribeVariables({
              meter: params.meter,
              period: params.plan,
              plan: `${params.plan === "year" ? "Annual" : "Monthly"}: Digital | ${
                params.currency
              }`,
              price: params.price,
            });
          }
          break;
      }
    },
    [mixpanel.result, openLoginDialog],
  );

  return {
    handleCustomEvent,
  };
};
