export const article = {
  types: {
    analysis: {
      entityId: "328387",
    },
    blogs: {
      entityId: "784",
    },
    column: {
      entityId: "783",
      entityUuid: "012d7708-2959-4b2b-9031-23e3d025a08d",
    },
    dailyPulseBriefing: {
      entityId: "517498",
      entityUuid: "09ecd76b-0271-467d-be05-c228b9a8ef47",
      name: "Daily Pulse",
    },
    debate: {
      entityId: "328051",
    },
    editorial: {
      entityId: "500285",
    },
    explainer: {
      entityId: "328386",
      name: "Explainer",
    },
    factCheck: {
      entityId: "328388",
    },
    factSheet: {
      entityId: "517499",
      entityUuid: "8b450c30-742e-4668-978c-1868587f603f",
      name: "Fact Sheet",
    },
    infographic: {
      entityId: "27557",
      name: "Infographic",
    },
    infographicLongRead: {
      entityId: "509798",
      name: "Infographic|Long Read",
    },
    letters: {
      entityId: "329083",
    },
    live: {
      entityId: "27556",
      entityUuid: "c95a7a01-e8fe-4d06-b8fd-248824cb9c5f",
      name: "Live",
    },
    longRead: {
      entityId: "328194",
      name: "Long Read",
    },
    newsletter: {
      entityId: "507042",
      name: "Newsletter",
    },
    obituary: {
      entityId: "328389",
    },
    opinion: {
      entityId: "328587",
      entityUuid: "175d77bc-2793-43ce-aa75-17d537ba2e51",
      name: "Opinion",
    },
    photoEssay: {
      entityId: "328710",
      name: "Photo essay",
    },
    podcast: {
      entityId: "328588",
    },
    posties: {
      entityId: "515415",
      entityUuid: "2a786249-ee3e-4fda-9991-0d757340f9a7",
      name: "Posties",
    },
    profile: {
      entityId: "509368",
    },
    qa: {
      entityId: "16560",
      name: "Q&A",
    },
    quickDigest: {
      entityId: "517593",
      entityUuid: "6dbdfc18-3199-4789-8a62-adfd9d292cf1",
      name: "Quick Digest",
    },
    review: {
      entityId: "318341",
      name: "Review",
    },
    series: {
      entityId: "506855",
      entityUuid: "35e8a7ae-0200-4a16-b195-195d825e2c5c",
      name: "Series",
    },
    seriesDefaultLabel: {
      entityId: "509605",
    },
    studio: {
      entityId: "328367",
    },
    studioMorningStudioLogoOnly: {
      entityId: "503583",
    },
    studioScmpLogoOnly: {
      entityId: "503582",
    },
  },
} as const;

export const starlingArticleEntityIds = new Set([
  "3176982",
  "3160321",
  "3160333",
  "3160475",
  "3160466",
  "3160469",
]);

export const acknowledgementGateArticleIds = new Set([
  "1780152",
  "1781385",
  "1783122",
  "1782752",
  "1785202",
  "1786367",
  "1787941",
  "1788985",
  "1854626",
  "1855229",
  "1857242",
  "1862338",
  "1863178",
  "1863522",
  "1784494",
  "1815689",
  "1840168",
  "1840737",
  "1944555",
  "2057808",
]);

export const articleSponsorType = new Set([
  "business_reports",
  "country_reports",
  "events",
  "native",
  "presented",
  "special_reports",
  "specialist_publications",
]);
